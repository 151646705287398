//
//** Widget 23 Component
//

//== Component Base
@mixin m-build-component--widget23-base() {
    .m-widget23 {    
        
    } 
}

//== Component Skin
@mixin m-build-component--widget23-skin() {
    $skin: get-default-skin();    

    .m-widget23 {    

    }
}

//== Build Component Base
@include m-build-component--widget23-base();

//== Build Component - Light Skin
@include m-build-component--widget23-skin();