//
//** Vertical Menu Extend Mixins
// 

//== Extends vertical menu markup
@mixin m-extend--menu-ver($config) {
	// Base vertical menu
	@if (array-get($config, base, mode) == 'general') {
		.#{array-get($config, base, class)} {
			@content;
		}
	} @else if (array-get($config, base, mode) == 'tablet-and-mobile') {
		@include tablet-and-mobile {
			.#{array-get($config, base, class)} {
				@content;
			}
		}
	} @else if (array-get($config, base, mode) == 'mobile') {
		@include mobile {
			.#{array-get($config, base, class)} {
				@content;
			}
		}
	}
}

//== Extends dropdown vertical menu
@mixin m-extend--menu-ver-dropdown($config) {
	// Base dropdown vertical menu
	@if (array-get($config, build, dropdown)) {
		// Desktop only dropdown vertical menu
		@include desktop {
			.#{array-get($config, base, class)}--minimize .#{array-get($config, base, class)},
			.#{array-get($config, base, class)}.#{array-get($config, base, class)}--dropdown {
				@content;
			}
		}
	}
}

//== Extends minimize vertical menu
@mixin m-extend--menu-ver-minimize($config) {
	@if (array-get($config, build, minimize)) {
		// Hoverable vertical menu for desktop and tablet modes
		@include desktop {
			.#{array-get($config, base, parent-class)}--minimize {
				.#{array-get($config, base, class)} {
					@content;
				}
			}
		}
	}	
}