$border: rgba(120, 130, 140, 0.13);

@media (min-width: 768px) {
    .dl-horizontal dt {
        float:left;
        // width: 200px;
        clear: left;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap
    }

    .dl-horizontal dd {
        margin-left: 250px;
    }
}

.dl-horizontal {
  dt {
    text-align: left;

    @media print {
      float: left;
    }
  }

  dd {
    margin-bottom: 0;
    @media print {
      text-align: right;
    }
  }

  &.dl-condensed {
    .dl-row {
      padding: .25em !important;
    }
  }

  &.dl-styled {
    .dl-row {
      border-bottom: 1px solid $border;
      padding: .7em;

      @media print {
        border-bottom: 1px solid $black;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

