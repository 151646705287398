/** Tagify Plugin Overrides **/
.tagify {
    padding: 0 !important;
}
.tagify__input {
    display: inline-block !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}